import React, { useContext, useState, useEffect } from "react";

import { AppContext } from "../AppContext";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card } from "react-bootstrap";

import savingImage1 from "../images/oil drop.svg";
import savingImage2 from "../images/cloud-01.svg";
import handImage from "../images/hand1-01.png";

function Results() {
  const intl = useIntl();

  const {
    prevStep,
    startAgain,
    products,
    calculateSavings,
    nextStep,
    state,
  } = useContext(AppContext);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    calculateSavings().then(
      () => {
        console.log("data retrieved");
        setIsLoaded(true);
      },
      (error) => {
        console.log(`error: ${error}`);
        setError(error);
        setIsLoaded(false);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <div>
        <div id="find-out-more-btn">
          <Button
            onClick={nextStep}
            className="more"
            style={
              state.step === 4
                ? { display: "", zIndex: "100" }
                : { display: "none" }
            }
          >
            <FormattedMessage id="button.more" />
            &nbsp;
            <img
              src={handImage}
              alt="check"
              style={{
                padding: "0px",
                width: "18%",
                ObjectFit: "cover",
                float: "right",
                transform: "rotate(90deg)",
              }}
            />
          </Button>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="text-secondary">
              <FormattedMessage id="results.title" />
            </h1>
            <h4 className="text-secondary">
              <FormattedMessage id="results.caption" />
            </h4>
          </div>
        </div>

        <div style={{ alignContent: "center" }}>
          {products
            .filter((product) => product.selected)
            .map((product, index) => {
              //calculateSavings(products[0], product);
              return (
                <Card
                  key={index}
                  className="row mb-3 resultsCard"
                  style={{ maxWidth: "70vw" }}
                >
                  <Card.Header className="bg-dark p-0 m-0">
                    <a
                      data-toggle="collapse"
                      href={`#product-${product.key}`}
                      className="btn btn-link text-secondary"
                      style={{ textDecoration: "none" }}
                    >
                      {product.label}
                    </a>
                    <a
                      data-toggle="collapse"
                      href={`#product-${product.key}`}
                      className=" arrow btn btn-link text-secondary"
                      style={{
                        color: "rgb(228,194,6)",
                        textDecoration: "none",
                        float: "right",
                      }}
                    >
                      <span style={{ color: "rgb(228,194,6)" }}>&#9650;</span>
                    </a>
                  </Card.Header>
                  <Card.Body
                    className="row m-0 p-1 collapse.show collapse show"
                    id={`product-${product.key}`}
                  >
                    <span className="text-center col-sm">
                      <div
                        className="text-info"
                        style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                      >
                        {intl.formatNumber(product.savings.annualSavings, {
                          style: "currency",
                          currency: "GBP",
                          minimumFractionDigits: "0",
                        })}
                      </div>
                      <div
                        className="text-info"
                        style={{ fontSize: "0.7rem", fontWeight: "bold" }}
                      >
                        Annual Savings
                      </div>
                      <div className="text-center">
                        <span className="primarycircle resultsImage">£</span>
                      </div>
                    </span>

                    <span className="text-center col-sm">
                      <div
                        className="text-dark"
                        style={{ fontSize: "1.4rem", fontWeight: "bold" }}
                      >
                        {intl.formatNumber(product.savings.fuelSavingLitres)}
                        &nbsp;Ltr
                      </div>
                      <div
                        className="text-dark"
                        style={{ fontSize: "0.7rem", whiteSpace: "nowrap" }}
                      >
                        Predicted fuel savings
                      </div>
                      <div style={{ marginTop: "9px" }}>
                        <img
                          className="resultsImage"
                          src={savingImage1}
                          alt="drop"
                        />
                      </div>
                    </span>

                    <span className="text-center col-sm">
                      <div
                        className="text-dark"
                        style={{ fontSize: "1.4rem", fontWeight: "bold" }}
                      >
                        {intl.formatNumber(product.savings.CO2KG)}
                        &nbsp;kg
                      </div>
                      <div
                        className="text-dark"
                        style={{ fontSize: "0.7rem", whiteSpace: "nowrap" }}
                      >
                        Calculated CO<sub>2</sub> reduction
                      </div>
                      <div style={{ marginTop: "9px" }}>
                        <img
                          className="resultsImage"
                          src={savingImage2}
                          alt="cloud"
                        />
                      </div>
                    </span>

                    <span className="text-center col-sm">
                      <div
                        className="text-dark"
                        style={{ fontSize: "1.4rem", fontWeight: "bold" }}
                      >
                        {intl.formatNumber(product.savings.fuelCost, {
                          style: "currency",
                          currency: "GBP",
                          minimumFractionDigits: "0",
                        })}
                      </div>
                      <div className="text-dark" style={{ fontSize: "0.7rem" }}>
                        Fuel cost
                      </div>
                      <div className="text-center">
                        <span className="secondarycircle resultsImage">£</span>
                      </div>
                    </span>

                    <span className="text-center col-sm">
                      <div
                        className="text-dark"
                        style={{ fontSize: "1.4rem", fontWeight: "bold" }}
                      >
                        {intl.formatNumber(product.savings.oilCost, {
                          style: "currency",
                          currency: "GBP",
                          minimumFractionDigits: "0",
                        })}
                      </div>
                      <div className="text-dark" style={{ fontSize: "0.7rem" }}>
                        Engine oil cost
                      </div>
                      <div className="text-center">
                        <span className="secondarycircle resultsImage">£</span>
                      </div>
                    </span>
                  </Card.Body>
                </Card>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Results;
