import React from "react";

import BasePage from "./components/BasePage";
import { AppProvider } from "./AppContext";

class App extends React.Component {
  render() {
    return (
      <AppProvider>
        <BasePage />
      </AppProvider>
    );
  }
}

export default App;
