const ProductsConfig =
  [
    {
      key: 0,
      label: "Current 15W-40 engine oil",
      short: "",
      image: "",
      selected: true,
      cost: 0,
      savings: {
        annualSavings: 0,
        fuelSavingLitres: 0,
        CO2KG: 0,
        fuelCost: 0,
        oilCost: 0,
      }
    },
    {
      key: 1,
      label: "Versimax HD9 10W-40",
      short: "",
      image: "",
      selected: true,
      cost: 0,
      savings: {
        annualSavings: 0,
        fuelSavingLitres: 0,
        CO2KG: 0,
        fuelCost: 0,
        oilCost: 0
      },
      additionalSavings: {
        freeTyres: 0,
        freeServices: 0,
        freeMiles: 0,
        sugarBags: 0
      }
    },
    {
      key: 2,
      label: "Versimax HD16 5W-30",
      short: "",
      image: "",
      selected: true,
      cost: 0,
      savings: {
        annualSavings: 0,
        fuelSavingLitres: 0,
        CO2KG: 0,
        fuelCost: 0,
        oilCost: 0
      },
      additionalSavings: {
        freeTyres: 0,
        freeServices: 0,
        freeMiles: 0,
        sugarBags: 0
      }
    }
  ]

export default ProductsConfig