import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import Button from "react-bootstrap/Button";

import { AppContext } from "../AppContext"

function Welcome() {

  const { nextStep } = useContext(AppContext)

  return (
    <div className="container m-0 p-0">
      <div className="row">
        <div className="col-12">
          <h1 className="text-secondary">
            <FormattedMessage id="welcome.title" className="text-secondary"/>
          </h1>
          <h4 className="text-secondary">
            <FormattedMessage id="welcome.caption" />
          </h4>
          <Button onClick={nextStep}>
            <FormattedMessage id="button.getstarted" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
