const FleetConfig = {
  fuelConsumption: {
    InitialValue: 8.5,
    Min: 1,
    Max: 30,
    Step: 1
  },
  oilSumpSize: {
    InitialValue: 40,
    Min: 4,
    Max: 100,
    Step: 1
  },
  oilDrainInterval: {
    InitialValue: 75000,
    Min: 5000,
    Max: 100000,
    Step: 1000
  },
  vehiclesInFleet: {
    InitialValue: 10,
    Min: 10,
    Max: 1000,
    Step: 1
  },
  annualMileage: {
    InitialValue: 75000,
    Min: 1000,
    Max: 100000,
    Step: 100
  },
  dieselCostPerLitre: {
    InitialValue: 1.2,
    Min: 1,
    Max: 3,
    Step: 0.01
  }
};

export default FleetConfig;
