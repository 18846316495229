import React, { useContext } from "react";

import { Button } from "react-bootstrap";

import { IntlProvider, FormattedMessage } from "react-intl";

import { AppContext } from "../AppContext";

import Login from "./Login";
import Calculator from "./Calculator";

import bgImage from ".././images/morris_Image2_1_A1_Rectangle_2_pattern.png";
import brandCalcImage from ".././images/Morris_lozenge_logo_A1_Rectangle_4_pattern.png";
import foreImage from ".././images/morris_versimax_hd16.png";

function BasePage() {
  const { config, state, toggleLoggedIn, prevStep, startAgain } = useContext(
    AppContext
  );

  const bgLoginStyle = {
    backgroundImage: `url(${bgImage})`,
  };

  const bgCalcStyle = {
    backgroundImage: `url(${bgImage})`,
  };

  function onLogin(user, password) {
    if (user === "a@a.com" && password === "123") {
      toggleLoggedIn();
    }
  }

  return (
    <IntlProvider locale={config.locale} messages={config.messages}>
      <div
        className="container-flex background"
        style={config.loggedIn ? bgCalcStyle : bgLoginStyle}
      >
        <img className="logo" src={brandCalcImage} alt="brand" />

        <div className="row p-1 main">
          <div className="col-sm-12">
            {config.loggedIn ? <Calculator /> : <Login onLogin={onLogin} />}
          </div>
        </div>
        <div
          className="footer align-items-top"
          style={config.loggedIn ? { display: "" } : { display: "none" }}
        >
          <div className="row pt-2" >
            <div className="col-sm-12 col-md-9">
              <a
                style={
                  state.step !== 4 && state.step !== 5
                    ? { display: "" }
                    : { display: "none" }
                }
                href={config.moreInfo}
                target="_blank"
                rel="noopener noreferrer"
                id="more-information-btn"
                className="bg-dark text-primary"
              >
                <span>
                  <FormattedMessage
                    id="footer.learn"
                    className="font-weight-bold col-sm-12"
                  />
                </span>
              </a>
              <div
                id="result-options"
                className="mt-2"
                style={state.step < 4 ? { display: "none" } : { display: "" }}
              >
                <div style={{ zIndex: 50 }}>
                  <span style={{ verticalAlign: "top" }}>
                    <Button
                      onClick={prevStep}
                      className="bg-dark text-secondary ml-1"
                      style={{ width: "100px" }}
                    >
                      <FormattedMessage id="button.back" />
                    </Button>
                    <Button
                      onClick={startAgain}
                      className="bg-dark text-secondary ml-1"
                    >
                      <FormattedMessage id="button.startagain" />
                    </Button>
                  </span>

                  <div
                    style={{ width: "25rem", display: "inline-block" }}
                    className="text-dark disclaimer ml-2"
                  >
                    <strong>
                      <FormattedMessage id="results.disclaimer.3" />
                    </strong>
                    <br />
                    <FormattedMessage id="results.disclaimer.2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">            
              <a
                href="https://www.morrislubricants.co.uk/products/cv/heavy-duty-diesel-engine-oils.html"
                className="bg-dark text-primary productInformation"
              >
                <span>Product Information</span>
                <img
                  id="product-information-img"
                  src={foreImage}
                  alt="foreImage"
                />
                <span style={{float:"right"}}>&gt;</span>
              </a>
            </div>
          </div>
          <div className="row"></div>
          <div className="row mt-3"></div>
        </div>
      </div>
    </IntlProvider>
  );
}

export default BasePage;
