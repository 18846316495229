import 'core-js/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/app.scss";

import "jquery/dist/jquery.min.js"
import "bootstrap/dist/js/bootstrap.min.js";


ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
