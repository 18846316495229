import React, { createContext, useState } from "react";

import locales from "./locales";
import FleetConfig from "./config/FleetConfig";
import ProductsConfig from "./config/ProductsConfig"
import axios from "axios"

export const AppContext = createContext();

export function AppProvider(props) {
  const [config, setConfig] = useState(
    {
      moreInfo: "https://www.morrislubricants.co.uk",
      languageSupport: false,
      loginSupport: false,
      locale: "en",
      messages: locales[`en`],
      loggedIn: true
    }
  )

  const [state, setState] = useState({
    step: 1,
    showPrices: true,
    fuelConsumption: FleetConfig.fuelConsumption.InitialValue,
    oilSumpSize: FleetConfig.oilSumpSize.InitialValue,
    oilDrainInterval: FleetConfig.oilDrainInterval.InitialValue,
    vehiclesInFleet: FleetConfig.vehiclesInFleet.InitialValue,
    annualMileage: FleetConfig.annualMileage.InitialValue,
    dieselCostPerLitre: FleetConfig.dieselCostPerLitre.InitialValue
  });

  const [products, setProducts] = useState(ProductsConfig)

  //Config
  function toggleLoggedIn() {
    setConfig((prevState) => ({ ...prevState, loggedIn: !config.loggedIn }))
  }

  function updateLanguage(language) {
    setConfig(prevState => ({
      ...prevState,
      locale: language,
      messages: locales[language]
    }))
  }

  //Calculator

  function toggleShowPrices() {
    setState((prevState) => ({ ...prevState, showPrices: !state.showPrices }))
  }

  const onChange = e => {
    let { name, value, min, max } = e.target
    // //value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    console.log(` onChange: target value: ${value} input: ${name} min: ${min} max: ${max}`)
    if (!isNaN(value))
      setState(prevState => ({ ...prevState, [name]: value }))
  }

  const onCostChange = input => e => {
    var value = e.target.value
    value = value.replace("£", "")
    console.log(value)

    //if (isNaN(value)) return
    var tempProducts = [...products]
    tempProducts.filter(product => (product.key === input)).map(product => product.cost = value)
    setProducts(prevProducts => (tempProducts))
  }

  const onSelectProduct = input => e => {
    console.log(`onSelect Product ${input}`)
    var tempProducts = [...products]
    tempProducts.filter(product => (product.key === input)).map(product => product.selected = !product.selected)
    setProducts(prevProducts => (tempProducts))
  };

  function nextStep() {
    setState(prevState => ({ ...prevState, step: state.step + 1 }));
  }

  function prevStep() {
    setState(prevState => ({ ...prevState, step: state.step - 1 }));
  }

  function startAgain() {
    setState(prevState => ({ ...prevState, step: 1 }));
  }

  function callService(){

    var p = products.map(
      product => (
        {
        key: product.key,
        cost: product.cost
        }
      )
    )

    let authOptions = {
      method: "POST",
      url : "https://fecalculator.azurewebsites.net/api/U01Calculator?code=RkmDdE96wJQ6nvoI0ObozJwWxb78PhCWytJRsBA4aJZamt4JCNCe7A==",
      headers: {
          "Content-Type": "application/json"
      },
      json: true,
      data : {
        Products : p,
        Fleet : state
      }
    }
    console.log("calling service")

    return axios(authOptions)
      .then(res => {
        return res.data
      })
  }

  function calculateSavings(){
    return callService().then(data => {
      var tempProducts = [...products]
      data.responses.forEach((item, index)=>
      {
        console.log(`item: ${JSON.stringify(item)}`)
        var product = tempProducts.find(x=> x.key === item.key)
        product.savings.fuelCost = item.totalFuelCost
        product.savings.fuelSavingLitres = item.totalFuelSavingLitres
        product.savings.CO2KG = item.totalCO2SavingKG
        product.savings.annualSavings = item.totalSaving
        product.savings.oilCost = item.totalOilCost
      })
      setProducts(tempProducts)
    })
}

  const TYRECOST = 350.0;
  const SERVICECOST = 1200.0;
  const SUGARWEIGHT = 1;
  const PERCENTCARBONINCO2 = 0.277
  const GALLONSTOLITRES = 4.54609;


  function calculateAdditionalSavings(product) {
    product.additionalSavings.freeTyres = Math.round(product.savings.annualSavings / TYRECOST)
    product.additionalSavings.freeServices = Math.round(product.savings.annualSavings / SERVICECOST)
    //product.additionalSavings.freeMiles = Math.round((product.savings.fuelSavingLitres / GALLONSTOLITRES) * state.fuelConsumption)
    product.additionalSavings.freeMiles = Math.round((product.savings.annualSavings / state.dieselCostPerLitre) / GALLONSTOLITRES * state.fuelConsumption)
    product.additionalSavings.sugarBags = Math.round((product.savings.CO2KG / SUGARWEIGHT) * PERCENTCARBONINCO2)
  }

  return (
    <AppContext.Provider
      value={{
        config,
        state,
        toggleLoggedIn,
        updateLanguage,
        onChange,
        nextStep,
        prevStep,
        startAgain,
        products,
        onSelectProduct,
        onCostChange,
        calculateSavings,
        calculateAdditionalSavings,
        toggleShowPrices
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}
