import React from "react";

import checkImage from "../images/tick-01.png";

function Checkbox({ label, value, onChange }) {

  function toggleVisibility(e) {
    e.target.style.opacity === "1"
      ? (e.target.style.opacity = "0")
      : (e.target.style.opacity = "1");
    onChange();
  }

  const getStyle = {
    width: "1.5rem",
    ObjectFit: "cover",
    float: "right",
    opacity: Number(value)
  };

  return (
    <div
      style={{
        border: "2px solid rgb(228, 194, 6)",
        borderRadius: "10px",
        width: "170px",
        height: "3rem",
        color: "white",
        display: "flex"
      }}
    >         <span style={{ lineHeight: "3rem", paddingLeft: "8px" }}>{label}</span>
      <div
        style={{
          backgroundColor: "white",
          border: "2px solid rgb(228, 194, 6)",
          width: "30px",
          height: "30px",
          display: "inline-block",
          textAlign: "right",
          alignSelf: "center",
          margin: "8px"
        }}
        onClick={e => toggleVisibility(e)}
      >
        <img
          id="custom-cb"
          src={checkImage}
          alt="checkbox"
          style={getStyle}
        />
      </div>
    </div>
  );
}

export default Checkbox;


