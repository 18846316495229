import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import InputGroup from "react-bootstrap/InputGroup";

import { Form, Button } from "react-bootstrap";

import Checkbox from "./Checkbox";

import calculatorImage from "../images/calculator icon-01.svg";

import { AppContext } from "../AppContext";

function Products() {
  const {
    state,
    nextStep,
    prevStep,
    toggleShowPrices,
    onCostChange,
    onSelectProduct,
    products,
  } = useContext(AppContext);

  function onSubmit(e) {
    e.preventDefault();
    nextStep();
  }

  return (
    <div>
      
      <Form validated onSubmit={(e) => onSubmit(e)}>
        <div className="row">
          <div className="col-12">
            <h1 className="text-secondary">
              <FormattedMessage id="oils.title" />
            </h1>
            <h4 className="text-secondary">
              <FormattedMessage id="oils.caption" />
            </h4>
          </div>
        </div>
        <div id="results-hide-oil-pricing">
        <Checkbox
          label="Hide oil pricing"
          value={!state.showPrices}
          onChange={toggleShowPrices}
        />
      </div>
        <div className="row mb-4">
          <div className="col-12">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="bg-primary font-weight-bold"
                  style={{
                    color: "black",
                    width: "300px",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{products[0].label}</span>
                  <span>£/Litre</span>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                required
                disabled={!state.showPrices}
                style={
                  state.showPrices
                    ? { display: "", maxWidth: "100px" }
                    : { display: "none" }
                }
                value={products[0].cost}
                onChange={onCostChange(products[0].key)}
              />
            </InputGroup>
          </div>
        </div>
        {products
          .filter((product) => product.key !== 0)
          .map((product) => (
            <div className="row mb-4" key={product.key}>
              <div className="col-12">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="font-weight-bold pr-6"
                      onClick={onSelectProduct(product.key)}
                      style={{
                        color:
                          (product.selected || product.key === 0) && "black",
                        backgroundColor:
                          (product.selected || product.key === 0) &&
                          "rgb(254,204,13)",
                        width: "300px",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{product.label}</span>
                      <span>£/Litre</span>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    required
                    disabled={!state.showPrices}
                    style={
                      state.showPrices
                        ? { display: "", maxWidth: "100px" }
                        : { display: "none" }
                    }
                    value={product.cost}
                    onChange={onCostChange(product.key)}
                  />
                </InputGroup>
              </div>
            </div>
          ))}

        <div className="row">
          <div className="col text-right">
            <Button onClick={(e) => prevStep()} className="mt-2 ml-1">
              <FormattedMessage id="button.back" />
            </Button>

            <Button type="submit" className="mt-2 ml-1">
              <FormattedMessage id="button.calculate" />
              <img
                src={calculatorImage}
                alt="check"
                style={{
                  padding: "0px",
                  width: "18%",
                  ObjectFit: "cover",
                  float: "right",
                }}
              />
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Products;
