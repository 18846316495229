import React, { useContext } from "react";

import Welcome from "./Welcome";
import Fleet from "./Fleet";
import Products from "./Products";
import Results from "./Results"
import More from "./More"

import { AppContext } from "../AppContext"

function Calculator(props) {

  const { state } = useContext(AppContext)

  switch (state.step) {
    case 1:
      return <Welcome />;
    case 2:
      return (
        <Fleet />
      );
    case 3:
      return (
        <Products />
      );
    case 4:
      return (
        <Results />
      );
    case 5:
      return (
        <More />
      );
    default:
      return <Welcome />;
  }
}

export default Calculator;
