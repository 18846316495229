import React, { useContext } from "react";

import { AppContext } from "../AppContext";

import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card } from "react-bootstrap";

import tyreImage from "../images/tyre-01.png";
import spannerImage from "../images/spanner-01.png";
import sugarImage from "../images/sugar-01.png";
import roadImage from "../images/road-01.png";

function More() {
  const intl = useIntl();

  const {
    prevStep,
    startAgain,
    products,
    calculateAdditionalSavings,
  } = useContext(AppContext);

  return (
    <React.Fragment>
      <div>
        <div className="row">
          <div className="col-12">
            <h1 className="text-secondary">
              <FormattedMessage id="more.title" />
            </h1>
            <h4 className="text-secondary">
              <FormattedMessage id="more.caption" />
            </h4>
          </div>
        </div>
        <div style={{ paddingLeft: "15px" }}>
          {products
            .filter((product) => product.selected && product.key !== 0)
            .map((product, index) => {
              calculateAdditionalSavings(product);
              return (
                <Card
                  key={index}
                  style={{ border: "0", maxWidth: "70vw" }}
                  className="row mb-1 p-0 m-0 resultsCard"
                >
                  <Card.Header
                    className="bg-dark col-12 p-0 m-0"
                    style={{
                      border: "0",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div className="text-secondary p-2">{product.label}</div>
                  </Card.Header>
                  <Card.Body
                    className="row m-0 p-0 collapse.show collapse show"
                    id={`product-${product.key}`}
                    style={{ background: "rgb(228, 194, 6)" }}
                  >
                    <span className="text-center col-sm-3 p-0 m-0">
                      <div
                        className="text-dark"
                        style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                      >
                        {intl.formatNumber(product.additionalSavings.freeTyres)}{" "}
                        Free Tyres
                      </div>
                      <div>
                        <img
                          className="savingsImage"
                          src={tyreImage}
                          alt="tyre"
                        />
                      </div>
                    </span>

                    <span className="text-center col-sm-3">
                      <div
                        className="text-dark"
                        style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                      >
                        {intl.formatNumber(
                          product.additionalSavings.freeServices
                        )}{" "}
                        Free Services
                      </div>
                      <div>
                        <img
                          className="savingsImage"
                          src={spannerImage}
                          alt="tyre"
                        />
                      </div>
                    </span>

                    <span className="text-center col-sm-3">
                      <div
                        className="text-dark"
                        style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                      >
                        {intl.formatNumber(product.additionalSavings.freeMiles)}{" "}
                        Free Miles
                      </div>
                      <div>
                        <img
                          className="savingsImage"
                          src={roadImage}
                          alt="tyre"
                        />
                      </div>
                    </span>

                    <span className="text-center col-sm-3">
                      <div
                        className="text-dark"
                        style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                      >
                        CO<sub>2</sub> Reduction
                      </div>
                      <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                        <img
                          className="savingsImage"
                          src={sugarImage}
                          alt="tyre"
                        />
                        <div>
                          {intl.formatNumber(
                            product.additionalSavings.sugarBags
                          )}{" "}
                          Bags of Sugar
                        </div>
                      </div>
                    </span>
                  </Card.Body>
                </Card>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default More;
