import React, { useContext } from "react";

import { useIntl } from "react-intl";

import { AppContext } from "../AppContext";

import FleetConfig from "../config/FleetConfig";

import { FormattedMessage } from "react-intl";
import InputGroup from "react-bootstrap/InputGroup";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function Fleet() {
  const intl = useIntl();

  const { state, nextStep, prevStep, onChange } = useContext(AppContext);

  function onSubmit(e) {
    e.preventDefault();
    nextStep();
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h1 className="text-secondary">
            <FormattedMessage id="fleet.title" />
          </h1>
          <h4 className="text-secondary">
            <FormattedMessage id="fleet.caption" />
          </h4>
        </div>
      </div>
      <Form validated onSubmit={(e) => onSubmit(e)} id="fleet-information-form">
        <div className="row mb-4">
          <div className="col-sm-12 col-md-6">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text style={{ width: "10rem", fontWeight: "bold" }}>
                  <FormattedMessage id="fleet.fuelconsumption.label" />
                </InputGroup.Text>
                <InputGroup.Text
                  className="font-weight-bold"
                  style={{ color: "rgb(228, 194, 6)", width: "4.5rem" }}
                >
                  Mpg
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="fuelConsumption"
                type="decimal"
                value={state.fuelConsumption}
                required
                onChange={onChange}
                placeholder={`${intl.formatNumber(
                  FleetConfig.fuelConsumption.Min
                )}-${intl.formatNumber(FleetConfig.fuelConsumption.Max)}`}
              />
              <Form.Control.Feedback type="valid"></Form.Control.Feedback>
            </InputGroup>
          </div>
          <div className="col-sm-12 col-md-6">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text style={{ width: "10rem", fontWeight: "bold" }}>
                  <FormattedMessage id="fleet.oilsumpsize.label" />
                </InputGroup.Text>
                <InputGroup.Text
                  className="font-weight-bold"
                  style={{ color: "rgb(228, 194, 6)", width: "4.5rem" }}
                >
                  Litres
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="oilSumpSize"
                type="number"
                value={state.oilSumpSize}
                required
                onChange={onChange}
                placeholder={`${intl.formatNumber(
                  FleetConfig.oilSumpSize.Min
                )}-${intl.formatNumber(FleetConfig.oilSumpSize.Max)}`}
              />
            </InputGroup>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-sm-12 col-md-6">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text style={{ width: "10rem", fontWeight: "bold" }}>
                  <FormattedMessage id="fleet.oildraininterval.label" />
                </InputGroup.Text>
                <InputGroup.Text
                  className="font-weight-bold"
                  style={{ color: "rgb(228, 194, 6)", width: "4.5rem" }}
                >
                  Miles
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="oilDrainInterval"
                type="number"
                value={state.oilDrainInterval}
                required
                onChange={onChange}
                placeholder={`${intl.formatNumber(
                  FleetConfig.oilDrainInterval.Min
                )}-${intl.formatNumber(FleetConfig.oilDrainInterval.Max)}`}
              />
            </InputGroup>
          </div>
          <div className="col-sm-12 col-md-6">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text style={{ width: "10rem", fontWeight: "bold" }}>
                  <FormattedMessage id="fleet.vehiclesinfleet.label" />
                </InputGroup.Text>
                <InputGroup.Text
                  className="font-weight-bold"
                  style={{ color: "rgb(228, 194, 6)", width: "4.5rem" }}
                >
                  #
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="vehiclesInFleet"
                type="number"
                value={state.vehiclesInFleet}
                required
                onChange={onChange}
                placeholder={`${intl.formatNumber(
                  FleetConfig.vehiclesInFleet.Min
                )}-${intl.formatNumber(FleetConfig.vehiclesInFleet.Max)}`}
              />
            </InputGroup>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-sm-12 col-md-6">
            <InputGroup>
              <InputGroup.Prepend style={{ backgroundColor: "rgb(0,0,0)" }}>
                <InputGroup.Text style={{ width: "10rem", fontWeight: "bold" }}>
                  <FormattedMessage id="fleet.annualmileage.label" />
                </InputGroup.Text>
                <InputGroup.Text
                  className="font-weight-bold"
                  style={{ color: "rgb(228, 194, 6)", width: "4.5rem" }}
                >
                  Miles
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="annualMileage"
                type="number"
                value={state.annualMileage}
                required
                onChange={onChange}
                placeholder={`${intl.formatNumber(
                  FleetConfig.annualMileage.Min
                )}-${intl.formatNumber(FleetConfig.annualMileage.Max)}`}
              />
            </InputGroup>
          </div>
          <div className="col-sm-12 col-md-6">
            <InputGroup>
              <InputGroup.Prepend style={{ backgroundColor: "rgb(0,0,0)" }}>
                <InputGroup.Text style={{ width: "10rem", fontWeight: "bold" }}>
                  <FormattedMessage id="fleet.dieselcost.label" />
                </InputGroup.Text>
                <InputGroup.Text
                  className="font-weight-bold"
                  style={{ color: "rgb(228, 194, 6)", width: "4.5rem" }}
                >
                  £/Litre
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="dieselCostPerLitre"
                type="decimal"
                value={state.dieselCostPerLitre}
                required
                onChange={onChange}
                placeholder={`${intl.formatNumber(
                  FleetConfig.dieselCostPerLitre.Min
                )}-${intl.formatNumber(FleetConfig.dieselCostPerLitre.Max)}`}
              />
            </InputGroup>
          </div>
        </div>
        <div className="row">
          <div className="col text-right">            
              <Button onClick={prevStep} className="mt-2 ml-1">
                <FormattedMessage id="button.back" />
              </Button>                        
              <Button type="submit" className="mt-2 ml-1">
                <FormattedMessage id="button.next" />
              </Button>            
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Fleet;
